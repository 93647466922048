import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const fetchTransactionsCards = createAsyncThunk(
  "cachedTransactionsCards/fetchData",
  async (params) => {
    try {
      const response = await api.get("Charges/top-header-cards", params);
      return {
        params,
        response: response,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushTransactionsCards = createAsyncThunk(
  "cachedTransactionsCards/flush",
  async () => true
);

const cachedTransactionsCardsSlice = createSlice({
  name: "cachedTransactionsCards",
  initialState: {
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    [fetchTransactionsCards.pending]: (state) => {
      state.loading = true;
    },
    [fetchTransactionsCards.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            data: response.data,
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          data: response.data,
        };
      }

      state.loading = false;
      state.error = null;
    },
    [fetchTransactionsCards.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [flushTransactionsCards.fulfilled]: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
});

export default cachedTransactionsCardsSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const fetchAccountsCards = createAsyncThunk(
  "cachedAccountsCards/fetchData",
  async (params) => {
    try {
      const response = await api.get("Accountscards/get", params);
      return {
        params,
        response: response,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const flushAccountsCards = createAsyncThunk(
  "cachedAccountsCards/flushStore",
  async () => true
);

const cachedAccounsCardsSlice = createSlice({
  name: "cachedAccountsCards",
  initialState: {
    data: null,
    error: null,
    loading: false,
  },
  extraReducers: {
    [fetchAccountsCards.pending]: (state) => {
      state.loading = true;
    },
    [fetchAccountsCards.fulfilled]: (state, action) => {
      const { params, response } = action.payload;
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            data: response.data,
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          data: response.data,
        };
      }

      state.loading = false;
      state.error = null;
    },
    [fetchAccountsCards.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [flushAccountsCards.fulfilled]: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
});

export default cachedAccounsCardsSlice.reducer;
